module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"zep7nyd"},"custom":{"families":["NZ Plate Webfont","message_plate_fontregular"],"urls":["/platefontv1.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.kiwiplates.nz","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kiwiplates","short_name":"KiwiPlates","start_url":"/","icon":"static/KPFavicon16.png","icons":[{"src":"/static/favicons/favicon_android_512x512.png","sizes":"512x512","type":"image/png"},{"src":"/static/favicons/favicon_android_192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/favicons/favicon_iphone_180x180.png","sizes":"180x180","type":"image/png"},{"src":"/static/favicons/favicon_iphone_120x120.png","sizes":"120x120","type":"image/png"}],"background_color":"#3dae2b","theme_color":"#3dae2b","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d9d6b8c02bb68a52dbc0a0f9fc7ecae2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#33A3DC","showSpinner":true,"trickle":false,"minimum":0.4},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KDS7M69","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
