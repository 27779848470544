export interface IVehicleType {
    name: string
    id: number
    letters: number
    largeCharWidth: number
    smallCharWidth: number
    spaceCharWidth: number
    maxChars: number
    maxWidth: number
};

type VehicleTypes = {
    Car: IVehicleType
    Trailer: IVehicleType
    Motorbike: IVehicleType
};

export const VehicleTypesSetting: VehicleTypes = {
    Car : {
        name: 'Car',
        id: 1,
        letters: 6,
        largeCharWidth: 55,
        smallCharWidth: 27.5,
        spaceCharWidth: 13.75,
        maxChars: 6,
        maxWidth: 330
    },
    Trailer: { 
        name: 'Trailer', 
        id: 2,
        letters: 5,
        largeCharWidth: 55,
        smallCharWidth: 27.5,
        spaceCharWidth: 13.75,
        maxChars: 5,
        maxWidth: 330
    },
    Motorbike: {
        name:'Motorbike', 
        id: 3,
        letters: 5,
        largeCharWidth: 55,
        smallCharWidth: 27.5,
        spaceCharWidth: 13.75,
        maxChars: 5,
        maxWidth: 275
    } 
};