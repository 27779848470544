import { PlateColor, ProductModel, TextColor, MessagePositionColor, CaptionPosition, Brand, PlateModel } from ".";

export class ProductModelCategory {
  brand?: Brand
  plateColor: PlateColor
  products: Array<ProductModel>
  constructor(plateColor: PlateColor, products: Array<ProductModel>, brand?: Brand) {
    this.brand = brand;
    this.plateColor = plateColor;
    this.products = products;
  }
  add(products: ProductModel[]) {
    products.forEach(p => this.addProduct(p));
  }
  addProduct(product: ProductModel) {
    const matchProductModel = this.products.find(p => p.id === product.id);
    if (matchProductModel !== undefined) {
      const matchIndex = this.products.findIndex(p => p.id === product.id);
      matchProductModel.addVariant(product);
      this.products.splice(matchIndex, 1, matchProductModel);
    } else {
      this.products.push(product);
    }
  }
  getTextColors(): TextColor[] {
    let textColors: TextColor[] = [];
    this.products.forEach(p => {
      p.variants !== undefined && p.variants.forEach(v => {
        if (textColors.find(tc => tc.id === v.textColor.id) === undefined) {
          textColors.push(v.textColor);
        }
      })
    })
    return textColors;
  }
  getProductModelsByDesignCode(frontDesignId: number, backDesignId: number): { front: PlateModel, back: PlateModel } | undefined {
    for (let p of this.products) {
      const models = p.getAllPlateModels();
      const front = models.find(p => p.plateDesignCode === frontDesignId);
      const back = models.find(p => p.plateDesignCode === backDesignId);
      if (front && back) {
        return {
          front: front,
          back: back
        }
      }
    }
  }
  getProductModelsByDesignCodeAndForegroundColour(frontDesignId: number, backDesignId: number, frontForegroundColourName: string, backForegroundColourName: string): { front: PlateModel, back: PlateModel } | undefined {
    for (let p of this.products) {
      const models = p.getAllPlateModels();
      const front = models.find(p => p.plateDesignCode === frontDesignId && p.textColor.name.trim().toLowerCase() === frontForegroundColourName.trim().toLowerCase());
      const back = models.find(p => p.plateDesignCode === backDesignId && p.textColor.name.trim().toLowerCase() === backForegroundColourName.trim().toLowerCase());
      if (front && back) {
        return {
          front: front,
          back: back
        }
      }
    }
  }
  getProductModelByTextColor(textColorId: number): ProductModel {
    for (let p of this.products) {
      if (p.variants !== undefined) {
        if (p.variants.find(v => v.textColor.id === textColorId) !== undefined) {
          return p;
        }
      }
    }
    throw new Error("text color " + textColorId + " is not found in the product models");
  }
  getAllCaptionPositionsAndMessageColors(): MessagePositionColor {
    let positions: CaptionPosition[] = [];
    let messageColors: TextColor[] = [];
    for (let product of this.products) {
      for (let model of product.getAllPlateModels()) {
        if (model.captionPositon !== undefined) {
          const position = model.captionPositon;
          if (positions.find(p => p.value === position.value) === undefined) {
            positions.push(position);
          }
          if (model.messageColors !== undefined) {
            for (let messageColor of model.messageColors) {
              if (messageColors.find(c => c.id === messageColor.id) === undefined) {
                messageColors.push(messageColor);
              }
            }
          }
        }
      }
    }
    return {
      captionPositions: positions,
      messageColors: messageColors
    }
  }
}