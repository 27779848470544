export interface ITransactionType {
    id: number
    name: string
    code: string
}

export const TransactionTypesSetting = {
    New:{
        id: 1,
        name: "New Sale",
        code: "new"
    },
    Redesign: {
        id: 2,
        name: "Redesign",
        code: "redesign"
    },
    Upgrade: {
        id: 3,
        name: "Upgrade",
        code: "upgrade"
    }
}