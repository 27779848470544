import { Checkout, Address } from "@models/Checkout/Checkout";
import { LaybyQuote } from "@models/index";
import { LOCALSTORAGE_AP, LOCALSTORAGE_CHECKOUT_CURRENT, LOCALSTORAGE_MODAL_RESET_PASSWORD } from ".";
import { LOCALSTORAGE_T } from ".";
import moment from 'moment'

export function getEmptyCheckout () {

    const emptyCheckout = {
        ownershipType: 'Individual',
        manufactureNow: false,
        ownerIsApplicant: true,
        isAutopay: false,
        isMultiPlate: false,
        promoSubscribed: true,
        applicantDetails: {
            email: '',
            title: '',
            firstName: '',
            lastName: '',
            phone: ''
        },
        ownerDetails: {
            email: '',
            title: '',
            firstName: '',
            lastName: '',
            phone: '',
        },
        companyDetails: {
            companyNumber: '',
            companyOwner: ''
        },
        vehicleDetails: {
            dob: null,
            driverLicense: '',
            existingCombination: '',
            vehicleMake: '',
            vehicleModel: '',
            vosa: []
        },
        ownerAddress: {
            building: '',
            city: '',
            fullAddress: '',
            postCode: '',
            street: '',
            suburb: '',
            unit: '',
            isManualAddress: false
        },
        deliveryAddress: {
            building: '',
            title: '',
            city: '',
            fullAddress: '',
            postCode: '',
            street: '',
            suburb: '',
            unit: '',
            firstName: '',
            lastName: '',
            sameAsOwnerAddress: true,
            isManualAddress: false,
            companyName: ''
        },
        surcharge: 0,
        total: 0,
        isGift: false,
        isLayby: false,
        laybyPreferredPaymentDay: moment().add(28, 'days').toDate(),
        laybyQuote: {
            bracketName: 'Monthly',
            depositAmount: 0,
            installmentCount: 3,
            installmentFrequency: 28,
            paymentAmount: 0,
            totalAmount: 0
        },
        paymentType: 2,
        isDealerOrder: false,
        dealerDetails: {
            dealerId: 0,
            salesPersonId: -1,
            dealerName: "",
            salesPersonName: "",
            salesPersonEmail: ""
        },
        dealerAddress: {
            building: '',
            city: '',
            fullAddress: '',
            postCode: '',
            street: '',
            suburb: '',
            unit: '',
            isManualAddress: false
        },
        isAcceptStatement: false,
        giftingType: 0,
        isGifting: false,
        giftingDetails: {
            to:'',
            from:'',
            message:'',
            isForMe:false
        }
    } as Checkout;
    return emptyCheckout;
}

export function getCurrentCheckout(){
    const currentCheckout = getEmptyCheckout();
    if (typeof window !== 'undefined') {
        const checkout: string | null = localStorage.getItem(LOCALSTORAGE_CHECKOUT_CURRENT);
        if(checkout !== null){
            return JSON.parse(checkout) as Checkout;
        }
        else {
            currentCheckout.laybyPreferredPaymentDay.toJSON = function(){ return moment(this).format(); }
            localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(currentCheckout));
            return currentCheckout;
        }
    }else{
        return currentCheckout as Checkout;
    }
}

export function saveOwnerIsApplicantToLocalStorage(ownerIsApplicant: boolean){
    const checkout = getCurrentCheckout();
    checkout.ownerIsApplicant = ownerIsApplicant;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveManufactureNowToLocalStorage(manufactureNow: boolean){
    const checkout = getCurrentCheckout();
    checkout.manufactureNow = manufactureNow;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveIsGiftToLocalStorage(isGift: boolean){
    const checkout = getCurrentCheckout();
    checkout.isGift = isGift;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveIsMultiPlateToLocalStorage(isMultiPlate: boolean){
    const checkout = getCurrentCheckout();
    checkout.isMultiPlate = isMultiPlate;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveIsLaybyToLocalStorage(isLayby: boolean){
    const checkout = getCurrentCheckout();
    checkout.isLayby = isLayby;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveLaybyPreferredPaymentDayToLocalStorage(preferredPaymentDay: Date){
    const checkout = getCurrentCheckout();
    checkout.laybyPreferredPaymentDay = preferredPaymentDay;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function savePersonalDetailsToLocalStorage(personalDetails: Checkout){
    const checkout = getCurrentCheckout();
    checkout.applicantDetails = personalDetails.applicantDetails;
    checkout.ownerDetails = personalDetails.ownerDetails;
    checkout.ownerAddress = personalDetails.ownerAddress;
    checkout.vehicleDetails = personalDetails.vehicleDetails;
    checkout.companyDetails = personalDetails.companyDetails;
    checkout.ownershipType = personalDetails.ownershipType;
    checkout.dealerDetails = personalDetails.dealerDetails;
    checkout.giftingDetails = personalDetails.giftingDetails;

    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveOwnerAddressToLocalStorage(ownerAddress: Address){
    const checkout = getCurrentCheckout();
    checkout.ownerAddress = ownerAddress;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveDeliveryAddressToLocalStorage(deliveryAddress: Address & {title: string, firstName: string, lastName: string, sameAsOwnerAddress: boolean, companyName: string}){
    const checkout = getCurrentCheckout();
    checkout.deliveryAddress = deliveryAddress;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}
export function savePaymentTypeToLocalStorage(paymentType: number){
    const checkout = getCurrentCheckout();
    checkout.paymentType = paymentType;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveTokenToLocalStorage(t: string){
    localStorage.setItem(LOCALSTORAGE_T, t);
}

export function saveShowModalResetPassword(t: string){
    localStorage.setItem(LOCALSTORAGE_MODAL_RESET_PASSWORD, t);
}

export function getShowModalResetPassword(){
    if (typeof window !== 'undefined') {
        var result = localStorage.getItem(LOCALSTORAGE_MODAL_RESET_PASSWORD) === "False" ? false : true;
        return result;
    }
    return true
}

export function getTokenFromLocalStorage(){
    var token =  localStorage.getItem(LOCALSTORAGE_T);
    return token;
}

export function saveLaybyQuoteToLocalStorage(laybyQuote: LaybyQuote){
    const checkout = getCurrentCheckout();
    checkout.laybyQuote = laybyQuote;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveIsAutopayToLocalStorage(isAutopay: boolean){
    const checkout = getCurrentCheckout();
    checkout.isAutopay = isAutopay;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}

export function saveIsDealerOrderToLocalStorage(isDealerOrder: boolean){
    let checkout = getCurrentCheckout();
    checkout.isDealerOrder = isDealerOrder;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}
export function saveDealerAddressToLocalStorage(dealerAddress: Address){
    let checkout = getCurrentCheckout();
    checkout.dealerAddress = dealerAddress;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}
export function deleteDeliveryAddressFromLocalStorage(){
    const checkout: string | null = localStorage.getItem(LOCALSTORAGE_CHECKOUT_CURRENT);
    if(checkout){
        let currentCheckout = JSON.parse(checkout) as Checkout;
        const emptyCheckout = getEmptyCheckout();
        currentCheckout.deliveryAddress = emptyCheckout.deliveryAddress
        localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(currentCheckout))
    }
}
export function saveIsAcceptStatementToLocalStorage(isAcceptStatement: boolean){
    const checkout = getCurrentCheckout();
    checkout.isAcceptStatement = isAcceptStatement;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout));
}
export function saveGiftingTypeToLocalStorage(giftingType: number){
    const checkout = getCurrentCheckout();
    checkout.giftingType = giftingType;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout))
}

export function saveIsGiftingToLocalStorage(isGifting: boolean){
    const checkout = getCurrentCheckout();
    checkout.isGifting = isGifting;
    localStorage.setItem(LOCALSTORAGE_CHECKOUT_CURRENT, JSON.stringify(checkout))
}

export function saveAllowAfterToLocalStorage(t: string){
    localStorage.setItem(LOCALSTORAGE_AP, t);
}

export function getAllowAfterToLocalStorage(){
    return  localStorage.getItem(LOCALSTORAGE_AP);
}