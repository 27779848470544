import {makeActionCreator} from './makeActionCreator'

const REFRESH_BASKET = 'REFRESH_BASKET'


export const REFRESH_BASKET_REQUEST = REFRESH_BASKET + "_REQUEST";
export const REFRESH_BASKET_SUCCESS = REFRESH_BASKET + '_SUCCESS'
export const REFRESH_BASKET_FAILURE = REFRESH_BASKET + '_FAILURE'

export const refreshBasketRequest = makeActionCreator(REFRESH_BASKET_REQUEST, 'apiUrl','basketId');

export const refreshBasketSuccess = makeActionCreator(REFRESH_BASKET_SUCCESS, "basket");

export const refreshBasketFailure = makeActionCreator(REFRESH_BASKET_FAILURE);