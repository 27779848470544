import {makeActionCreator} from './makeActionCreator'

const ADD_BASKETLINE = 'ADD_BASKETLINE'


export const ADD_BASKETLINE_REQUEST = ADD_BASKETLINE + "_REQUEST";
export const ADD_BASKETLINE_SUCCESS = ADD_BASKETLINE + '_SUCCESS'
export const ADD_BASKETLINE_FAILURE = ADD_BASKETLINE + '_FAILURE'

export const addBasketLineRequest = makeActionCreator(ADD_BASKETLINE_REQUEST,'apiUrl', 'basketId', 'basketLine', 'isCheckout');

export const addBasketLineSuccess = makeActionCreator(ADD_BASKETLINE_SUCCESS, "basket");

export const addBasketLineFailure = makeActionCreator(ADD_BASKETLINE_FAILURE);