import { ProductSize, CaptionPosition, TextColor, ProductVariant, PlateModel } from "."
import { VehicleType } from "./"
import { AuthorizedDealers } from "./AuthorizedDealers"

export class ProductModel {
    name: string
    id : number
    size: ProductSize
    dealerOnly?: boolean
    disableDualSizing?: boolean
    vehicleType?: VehicleType
    caption?: CaptionPosition
    captionMaxLength?: number
    bottomCaptionMaxLength?: number
    messageColors?: TextColor[]
    variants?: Array<ProductVariant>
    authorizedDealers?: AuthorizedDealers
    constructor(name: string, id: number, size: ProductSize,  variants?: Array<ProductVariant>,caption?: CaptionPosition,captionMaxLength?: number, messageColors?: TextColor[],vehicleType?: VehicleType, dealerOnly?: boolean, authorizedDealers?: AuthorizedDealers, disableDualSizing?: boolean, bottomCaptionMaxLength?: number){
        this.name = name;
        this.id = id;
        this.size = size;
        this.vehicleType = vehicleType;
        this.dealerOnly = dealerOnly;
        this.variants = variants;
        this.captionMaxLength = captionMaxLength;
        this.caption = caption;
        this.messageColors = messageColors;
        this.authorizedDealers = authorizedDealers;
        this.disableDualSizing = disableDualSizing,
        this.bottomCaptionMaxLength = bottomCaptionMaxLength
    }
    addVariant(product: ProductModel){
      if(this.variants === undefined){
        this.variants = [];
      }
      if(product.variants !== undefined){
        this.variants = [...this.variants,...product.variants];
      }
    }
    getProductSizes(): ProductSize[]{
      let ps:ProductSize[] = [];
      ps.push(this.size);
      if(this.variants !== undefined){
        this.variants.forEach(element => {
          if(element.dualProduct !== undefined){
            const dualProductSizeId = element.dualProduct.size.id;
            if(ps.find(p=>p.id === dualProductSizeId) === undefined){
              ps.push(element.dualProduct.size);
            }
          }
        });
      }
      return ps;
    }
    getAllPlateModels(): PlateModel[]{
      let result : PlateModel[] = [];
      if(this.variants !== undefined){
        this.variants.forEach(v=>{
          result.push(
            {
              plateDesignCode: this.id,
              disableDualSizing: this.disableDualSizing,
              plateSize: this.size,
              textColor: v.textColor,
              captionPositon: this.caption,
              messageColors: this.messageColors

            });
          if(v.dualProduct !== undefined){
            result.push(
                {
                  plateDesignCode: v.dualProduct.id,
                  disableDualSizing: this.disableDualSizing,
                  plateSize: v.dualProduct.size,
                  textColor: v.textColor,
                  captionPositon: v.dualProduct.caption,
                  messageColors: v.dualProduct.messageColors});
          }
        })
      }
      return result;
    }
  }