import {makeActionCreator} from './makeActionCreator'

const DELETE_BASKETLINE = 'DELETE_BASKETLINE'


export const DELETE_BASKETLINE_REQUEST = DELETE_BASKETLINE + "_REQUEST";
export const DELETE_BASKETLINE_SUCCESS = DELETE_BASKETLINE + '_SUCCESS'
export const DELETE_BASKETLINE_FAILURE = DELETE_BASKETLINE + '_FAILURE'

export const deleteBasketLineRequest = makeActionCreator(DELETE_BASKETLINE_REQUEST,'apiUrl', 'basketId', 'basketLineId');

export const deleteBasketLineSuccess = makeActionCreator(DELETE_BASKETLINE_SUCCESS,'basket');

export const deleteBasketLineFailure = makeActionCreator(DELETE_BASKETLINE_FAILURE);