import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import { Checkout } from "@models/Checkout/Checkout";
import { LOCALSTORAGE_BASKET_CURRENT, LOCALSTORAGE_CHECKOUT_CURRENT, LOCALSTORAGE_PAYMENT } from ".";
import { getEmptyCheckout } from "./checkout";
import Cookies from "js-cookie";
import { PaymentOptions } from "@utils/Constant";

export function getEmptyBasket () {
    return {
        basketId: '',
        summary: {
            allowPaymentLayby:false,
            discount:0,
            finalTotal:0,
            gst:0,
            itemTotal:0,
            promotionCode:''
        },
        loading: false,
        success: true,
        basketLines: []
    } as BasketResponseModel;
}

export function getCurrentBasket(){
    if (typeof window !== 'undefined') {
        const basket: string | null = localStorage.getItem(LOCALSTORAGE_BASKET_CURRENT);
        let currentBasket : BasketResponseModel
        if(basket === null){
            currentBasket = getEmptyBasket();
        }else{
            currentBasket = JSON.parse(basket) as BasketResponseModel;
        }
        return currentBasket;
    }else{
        return getEmptyBasket();
    }
}

// export function saveBasketLine(newBasketLine: BasketLineResponseModel){
//     let basket: BasketResponseModel = getCurrentBasket();

//     let isNewBasketLine = true as boolean;

//     if(basket !== undefined){
//         basket.basketLines.forEach((basketLine) => {
//             if(basketLine.basketLineId === newBasketLine.basketLineId){
//                 isNewBasketLine = false;
//                 basketLine = newBasketLine;
//             }
//         });

//         if(isNewBasketLine){
//             basket.basketLines.push(newBasketLine);
//         }

//         saveBasketResponseToLocalStorage(basket);
//     }
// }

export function deleteBasketLineFromLocalStorage(basketLineId: string){
    const basket: string | null = localStorage.getItem(LOCALSTORAGE_BASKET_CURRENT);
    if(basket){
        const currentBasket = JSON.parse(basket) as BasketResponseModel;
        const newBasketLines = currentBasket.basketLines.filter(b=>b.basketLineId !== basketLineId);
        const newBasket = {basketId: currentBasket.basketId, basketLines: newBasketLines};
        localStorage.setItem(LOCALSTORAGE_BASKET_CURRENT, JSON.stringify(newBasket));
    };
}

export function saveBasketResponseToLocalStorage(newBasket:BasketResponseModel){
    const updatedBasket =
    {
        basketId: newBasket.basketId,
        basketLines: newBasket.basketLines,
        summary: newBasket.summary
    };

    localStorage.setItem(LOCALSTORAGE_BASKET_CURRENT, JSON.stringify(updatedBasket));
}

export function  setPaymentMethod(payment_type: number){
    switch (payment_type) {
      case PaymentOptions.CreditCard:
        savePaymentMetodToLocalStorage('Credit Card')
        break;
    case PaymentOptions.Account2Account:
        savePaymentMetodToLocalStorage('A2A')
        break;
    case PaymentOptions.Afterpay:
        savePaymentMetodToLocalStorage('Afterpay')
        break;
    case PaymentOptions.DealerPaymentRequest:
        savePaymentMetodToLocalStorage('DealerPaymentRequest')
        break;
    case PaymentOptions.Gem:
        savePaymentMetodToLocalStorage('Gem')
        break;
    case PaymentOptions.KiwiplatesLayby:
        savePaymentMetodToLocalStorage('Layby')
        break;
    default:
        break;
    }
  }

export function savePaymentMetodToLocalStorage(payment_type: string){
    Cookies.set(LOCALSTORAGE_PAYMENT, payment_type)
    localStorage.setItem(LOCALSTORAGE_PAYMENT, payment_type);
}

export function getPaymentMetodToLocalStorage(){
    localStorage.getItem(LOCALSTORAGE_PAYMENT);
}

export function deleteBasketAndCheckoutFromLocalStorage(){
    localStorage.removeItem(LOCALSTORAGE_BASKET_CURRENT);
    localStorage.removeItem(LOCALSTORAGE_CHECKOUT_CURRENT);
}

export function deleteBasketFromLocalStorage(){
    localStorage.removeItem(LOCALSTORAGE_BASKET_CURRENT);
}

