import {Record, fromJS} from 'immutable'
import {createReducer} from './createReducer'
import { BasketResponseModel } from '@models/Basket/BasketResponseModel';
import { getCurrentBasket , getEmptyBasket} from '../localStorage/basket';
import { ADD_BASKETLINE_SUCCESS, ADD_BASKETLINE_FAILURE, ADD_BASKETLINE_REQUEST } from '../actions/saveBasket';
import { DELETE_BASKETLINE_SUCCESS, DELETE_BASKETLINE_REQUEST, DELETE_BASKETLINE_FAILURE } from '@redux/actions/deleteBasketLine';
import { REFRESH_BASKET_REQUEST, REFRESH_BASKET_FAILURE, REFRESH_BASKET_SUCCESS } from '@redux/actions/refreshBasket';
import { CLEAR_BASKET } from '@redux/actions/checkout';

const initialState = getCurrentBasket() as BasketResponseModel;

export const currentBasket = createReducer(initialState,
    {
        [ADD_BASKETLINE_REQUEST](state: Record<BasketResponseModel>){
            return state.set('loading',true);
        },
        [ADD_BASKETLINE_SUCCESS](state: Record<BasketResponseModel>, data: any){
            return  state.set('loading',false)
                        .set('success',true)
                        .set('basketId',data.basket.basketId)
                        .set('basketLines',data.basket.basketLines)
                        .set('summary',data.basket.summary);
        },
        [ADD_BASKETLINE_FAILURE](state: Record<BasketResponseModel>, data: any){
            return state.set('loading',false)
                        .set('success',false);
        },
        [DELETE_BASKETLINE_REQUEST](state: Record<BasketResponseModel>, data: any){
            return state.set('loading',true)
                        .set('success',false);
        },
        [DELETE_BASKETLINE_FAILURE](state: Record<BasketResponseModel>, data: any){
            return state.set('loading',false)
                        .set('success',false);
        },
        [DELETE_BASKETLINE_SUCCESS](state:Record<BasketResponseModel>, data: any){
            return state.set('loading',false)
                        .set('success',true)
                        .set('basketId',data.basket.basketId)
                        .set('basketLines',data.basket.basketLines)
                        .set('summary',data.basket.summary);
        },
        [REFRESH_BASKET_REQUEST](state: Record<BasketResponseModel>, data: any){
            return state.set('loading',true)
                        .set('success',false);
        },
        [REFRESH_BASKET_FAILURE](state: Record<BasketResponseModel>, data: any){
            return state.set('loading',false)
                        .set('success',false);
        },
        [REFRESH_BASKET_SUCCESS](state:Record<BasketResponseModel>, data: any){
            return state.set('loading',false)
                        .set('success',true)
                        .set('basketId',data.basket.basketId)
                        .set('basketLines',data.basket.basketLines)
                        .set('summary',data.basket.summary);
        },
        [CLEAR_BASKET](state:Record<BasketResponseModel>, data:any){
            const basket = getEmptyBasket();

            return fromJS(basket);
        }
    }
)

