import {call, take, takeEvery, put, select} from 'redux-saga/effects'
import { ADD_BASKETLINE_REQUEST, ADD_BASKETLINE_SUCCESS, ADD_BASKETLINE_FAILURE } from '../actions/saveBasket';
import { getNewBasketIdService, addBasketLineItemService, getBasketResponseService, deleteBasketLineService, removeBasketPromotion } from '@services/BasketService';
import { saveBasketResponseToLocalStorage, deleteBasketAndCheckoutFromLocalStorage, getCurrentBasket, deleteBasketFromLocalStorage, deleteBasketLineFromLocalStorage, deleteDeliveryAddressFromLocalStorage } from '@redux/localStorage/basket';

import { navigate } from 'gatsby'
import { DELETE_BASKETLINE_REQUEST, DELETE_BASKETLINE_SUCCESS, DELETE_BASKETLINE_FAILURE } from '@redux/actions/deleteBasketLine';
import { currentBasketSelector } from '@redux/selectors/basketSelector';
import { BasketLineResponseModel } from '@models/Basket/BasketLineResponseModel';
import { REFRESH_BASKET_REQUEST, REFRESH_BASKET_SUCCESS, REFRESH_BASKET_FAILURE } from '@redux/actions/refreshBasket';
import { CLEAR_BASKET_AND_CHECKOUT, CLEAR_BASKET, CLEAR_CHECKOUT, REMOVE_PROMOTION, CLEAR_DELIVERY_ADDRESS } from '@redux/actions/checkout';
import { getCurrentCheckout, saveManufactureNowToLocalStorage } from '@redux/localStorage/checkout';
import { isMobile } from '@utils/Helpers';
import { TransactionTypesSetting } from '@models/ProductModels';

export function* deleteBasketLine(){
    yield takeEvery(DELETE_BASKETLINE_REQUEST, processDeleteBasketLineRequest)
}

export function* processDeleteBasketLineRequest(data: any){

    const deletedSuccess = yield call(deleteBasketLineService,data.apiUrl, data.basketId, data.basketLineId);
    if(deletedSuccess){
        //get basket from api
        const response = yield call (getBasketResponseService,data.apiUrl, data.basketId)
        yield put({type: DELETE_BASKETLINE_SUCCESS, basket: response.basket})
        yield call(saveBasketResponseToLocalStorage, response.basket)
        //yield call(deleteBasketLineFromLocalStorage, response.basket.basketId)
    }else{
        console.log('delete', deletedSuccess)

        yield call(deleteBasketAndCheckoutFromLocalStorage)
        yield put ({type: DELETE_BASKETLINE_FAILURE})
        yield put({type: CLEAR_BASKET})
    }
}

export function* refreshBasket(){
    while(true){
        const result = yield take(REFRESH_BASKET_REQUEST)
        const basketId = result.basketId
        if(basketId){
            const apiUrl = result.apiUrl;
            const response = yield call (getBasketResponseService, apiUrl, basketId)

            if(response.success){
                //trigger another reducer action to update reducer state
                yield put({type: REFRESH_BASKET_SUCCESS, basket: response.basket});
                //save basket to local storage
                yield call(saveBasketResponseToLocalStorage, response.basket)
            }else{
                //trigger another reducer action to update reducer state
                yield put({type: REFRESH_BASKET_FAILURE});
            }
        }
        else {
            //trigger another reducer action to update reducer state
            yield put({type: REFRESH_BASKET_FAILURE});
        }
    }
}

export function* removeBasketPromo(){
    while(true){
        const result = yield take(REMOVE_PROMOTION)
        const basketId = result.basketId
        const apiUrl = result.apiUrl;
        yield call (removeBasketPromotion, apiUrl, basketId);

        yield put({type: REFRESH_BASKET_REQUEST, apiUrl:apiUrl,  basketId: basketId})
    }
}


export function* clearBasketAndCheckout(){
    while(true){
        yield take (CLEAR_BASKET_AND_CHECKOUT)
        //clear redux states for basket and checkout
        yield call(deleteBasketAndCheckoutFromLocalStorage)
        //clear local storage for checkout and basket
        yield put({type: CLEAR_BASKET})
        yield put({type: CLEAR_CHECKOUT})
    }
}

export function* addBasketLine(){
    while(true){
        //get add basket line data from action
        const result = yield take(ADD_BASKETLINE_REQUEST);

        let basketId = result.basketId;
        const apiUrl = result.apiUrl;
        //get basket id is basket id is empty
        if(!basketId){
            basketId = yield call (getNewBasketIdService,apiUrl);
        }
        const basket = yield select(currentBasketSelector);
        const basketLines: BasketLineResponseModel[] = basket.toJS().basketLines;
        const basketLineExist = basketLines.find(bl=>bl.combination.toUpperCase() === result.basketLine.combination.toUpperCase());
        if(basketLineExist !== undefined){
            yield call(deleteBasketLineService,apiUrl, basketId, basketLineExist.basketLineId);
        }
        //save basket line
        yield call (addBasketLineItemService,apiUrl, basketId, result.basketLine);
        //get basket from api
        const response = yield call (getBasketResponseService,apiUrl, basketId)
        if(response.success){
            //trigger another reducer action to update reducer state
            yield put({type: ADD_BASKETLINE_SUCCESS, basket: response.basket});
            //save basket to local storage
            yield call(saveBasketResponseToLocalStorage, response.basket)
            const checkout = getCurrentCheckout()
            if (checkout.isDealerOrder) {
                if(response.basket && response.basket.basketLines && response.basket.basketLines[0].transactionTypeId != TransactionTypesSetting.New.id){
                    yield call(saveManufactureNowToLocalStorage, true)
                }
                yield window.location.href='/plate-manufacture-selection'
            } else {
                (isMobile() && !result.isCheckout) ? yield call(forwardTo,'/add-to-cart/?combination=' + result.basketLine.combination.toUpperCase()) : yield call(forwardTo,'/shopping-cart')
            }
        }else{
            //trigger another reducer action to update reducer state
            yield put({type: ADD_BASKETLINE_FAILURE});
        }
    }
}

export function* clearBasket(){
    while(true){
        yield take (CLEAR_BASKET)
        yield call(deleteBasketFromLocalStorage)
        yield put({type: CLEAR_BASKET})
    }
}

function forwardTo(location:string){
    navigate(location);
}
