import {Record, fromJS} from 'immutable'
import {createReducer} from './createReducer'
import { getCurrentCheckout, saveOwnerIsApplicantToLocalStorage, saveIsAutopayToLocalStorage, saveManufactureNowToLocalStorage, saveIsLaybyToLocalStorage, saveIsGiftToLocalStorage, savePersonalDetailsToLocalStorage, saveOwnerAddressToLocalStorage, saveIsMultiPlateToLocalStorage, saveDeliveryAddressToLocalStorage, savePaymentTypeToLocalStorage, saveLaybyQuoteToLocalStorage, getEmptyCheckout, saveLaybyPreferredPaymentDayToLocalStorage, saveIsDealerOrderToLocalStorage, saveDealerAddressToLocalStorage, deleteDeliveryAddressFromLocalStorage, saveIsAcceptStatementToLocalStorage, saveGiftingTypeToLocalStorage, saveIsGiftingToLocalStorage } from '@redux/localStorage/checkout';
import { Checkout } from '@models/Checkout/Checkout';
import { UPDATE_OWNER_IS_APPLICANT, UPDATE_IS_LAYBY, UPDATE_IS_GIFT, UPDATE_MANUFACTURE_NOW, UPDATE_PERSONAL_DETAILS, UPDATE_OWNER_ADDRESS, UPDATE_IS_MULTIPLATE, UPDATE_PAYMENTTYPE, UPDATE_DELIVERY_ADDRESS, UPDATE_LAYBY_QUOTE, UPDATE_IS_AUTOPAY, CLEAR_CHECKOUT, UPDATE_LAYBY_PREFERRED_PAYMENT_DAY, UPDATE_IS_DEALERORDER, UPDATE_DEALER_ADDRESS, CLEAR_DELIVERY_ADDRESS, UPDATE_IS_ACCEPT_STATEMENT, UPDATE_GIFTING_TYPE, UPDATE_IS_GIFTING } from '@redux/actions/checkout';

const initialState = getCurrentCheckout() as Checkout;

export const currentCheckout = createReducer(initialState,
    {
        [UPDATE_OWNER_IS_APPLICANT](state: Record<Checkout>, data: any){
            saveOwnerIsApplicantToLocalStorage(data.ownerIsApplicant);
            return state.set('ownerIsApplicant', data.ownerIsApplicant);
        },
        [UPDATE_MANUFACTURE_NOW](state: Record<Checkout>, data: any){
            saveManufactureNowToLocalStorage(data.manufactureNow);
            return state.set('manufactureNow', data.manufactureNow);
        },
        [UPDATE_IS_LAYBY](state: Record<Checkout>, data: any){
            saveIsLaybyToLocalStorage(data.isLayby);
            return state.set('isLayby', data.isLayby);
        },
        [UPDATE_IS_GIFT](state: Record<Checkout>, data: any){
            saveIsGiftToLocalStorage(data.isGift);
            return state.set('isGift', data.isGift);
        },
        [UPDATE_IS_MULTIPLATE](state: Record<Checkout>, data: any){
            saveIsMultiPlateToLocalStorage(data.isMultiPlate);
            return state.set('isMultiPlate', data.isMultiPlate);
        },
        [UPDATE_PERSONAL_DETAILS](state: Record<Checkout>, data: any){
            savePersonalDetailsToLocalStorage(data.checkout);
            return state.set('applicantDetails', data.checkout.applicantDetails)
                        .set('ownerDetails', data.checkout.ownerDetails)
                        .set('ownershipType', data.checkout.ownershipType)
                        .set('companyDetails', data.checkout.companyDetails)
                        .set('vehicleDetails', data.checkout.vehicleDetails)
                        .set('ownerAddress', data.checkout.ownerAddress)
                        .set('promoSubscribed',data.checkout.promoSubscribed)
                        .set('dealerDetails',data.checkout.dealerDetails)
                        .set('giftingDetails', data.checkout.giftingDetails)
        },
        [UPDATE_LAYBY_PREFERRED_PAYMENT_DAY](state: Record<Checkout>, data: any){
            saveLaybyPreferredPaymentDayToLocalStorage(data.laybyPreferredPaymentDay);
            return state.set('laybyPreferredPaymentDay', data.laybyPreferredPaymentDay);
        },
        [UPDATE_OWNER_ADDRESS](state: Record<Checkout>, data: any){
            saveOwnerAddressToLocalStorage(data.ownerAddress);
            return state.set('ownerAddress', data.ownerAddress);
        },
        [UPDATE_DELIVERY_ADDRESS](state: Record<Checkout>, data: any){
            saveDeliveryAddressToLocalStorage(data.deliveryAddress);
            return state.set('deliveryAddress', data.deliveryAddress);
        },
        [UPDATE_PAYMENTTYPE](state: Record<Checkout>, data: any){
            savePaymentTypeToLocalStorage(data.paymentType);
            return state.set('paymentType', data.paymentType);
        },
        [UPDATE_LAYBY_QUOTE](state: Record<Checkout>, data: any){
            saveLaybyQuoteToLocalStorage(data.laybyQuote);
            return state.set('laybyQuote', data.laybyQuote);
        },
        [UPDATE_IS_AUTOPAY](state: Record<Checkout>, data: any){
            saveIsAutopayToLocalStorage(data.isAutopay);
            return state.set('isAutopay', data.isAutopay);
        },
        [UPDATE_IS_DEALERORDER](state: Record<Checkout>, data: any){
            saveIsDealerOrderToLocalStorage(data.isDealerOrder)
            return state.set('isDealerOrder', data.isDealerOrder);
        },
        [UPDATE_DEALER_ADDRESS](state: Record<Checkout>, data: any){
            saveDealerAddressToLocalStorage(data.dealerAddress)
            return state.set('dealerAddress', data.dealerAddress);
        },
        [CLEAR_CHECKOUT](state: Record<Checkout>, data: any){
            return  fromJS(getEmptyCheckout());
        },
        [CLEAR_DELIVERY_ADDRESS](state: Record<Checkout>, data: any){
            deleteDeliveryAddressFromLocalStorage()
            return  state.set("deliveryAddress", data.dealerAddress);
        },
        [UPDATE_IS_ACCEPT_STATEMENT](state: Record<Checkout>, data: any){
            saveIsAcceptStatementToLocalStorage(data.isAcceptStatement);
            return state.set('isAcceptStatement', data.isAcceptStatement);
        },
        [UPDATE_GIFTING_TYPE](state: Record<Checkout>, data: any){
            saveGiftingTypeToLocalStorage(data.giftingType);
            return state.set('giftingType', data.giftingType);
        },
        [UPDATE_IS_GIFTING](state: Record<Checkout>, data: any){
            saveIsGiftingToLocalStorage(data.isGifting);
            return state.set('isGifting', data.isGifting);
        }
    }
)

