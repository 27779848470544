import exp from 'constants';
import {makeActionCreator} from './makeActionCreator'

export const UPDATE_OWNER_IS_APPLICANT = "UPDATE_OWNER_IS_APPLICANT";
export const UPDATE_MANUFACTURE_NOW = "UPDATE_MANUFACTURE_NOW";
export const UPDATE_IS_LAYBY = "UPDATE_IS_LAYBY";
export const UPDATE_LAYBY_QUOTE = "UPDATE_LAYBY_QUOTE";
export const UPDATE_IS_GIFT = "UPDATE_IS_GIFT";
export const UPDATE_IS_MULTIPLATE = "UPDATE_IS_MULTIPLATE";
export const UPDATE_LAYBY_PREFERRED_PAYMENT_DAY = "UPDATE_LAYBY_PREFERRED_PAYMENT_DAY";
export const UPDATE_PERSONAL_DETAILS = "UPDATE_PERSONAL_DETAILS";
export const UPDATE_OWNER_ADDRESS = "UPDATE_OWNER_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const UPDATE_PAYMENTTYPE = "UPDATE_PAYMENTTYPE";
export const UPDATE_IS_AUTOPAY = "UPDATE_IS_AUTOPAY";
export const UPDATE_IS_DEALERORDER = "UPDATE_IS_DEALERORDER";
export const UPDATE_DEALER_ADDRESS = "UPDATE_DEALER_ADDRESS";
export const UPDATE_IS_ACCEPT_STATEMENT = "UPDATE_IS_ACCEPT_STATEMENT";
export const UPDATE_GIFTING_TYPE = "UPDATE_GIFTING_TYPE";
export const UPDATE_IS_GIFTING = "UPDATE_IS_GIFTING";
export const UPDATE_GIFTING_DETAILS = "UPDATE_GIFTING_DETAILS";
export const CLEAR_BASKET_AND_CHECKOUT = "CLEAR_BASKET_AND_CHECKOUT";
export const CLEAR_BASKET = "CLEAR_BASKET"
export const CLEAR_CHECKOUT = "CLEAR_CHECKOUT"
export const CLEAR_DELIVERY_ADDRESS = "CLEAR_DELIVERY_ADDRESS"

export const REMOVE_PROMOTION ="REMOVE_PROMOTION";


export const updateOwnerIsApplicant = makeActionCreator(UPDATE_OWNER_IS_APPLICANT, 'ownerIsApplicant');
export const updateManufactureNow = makeActionCreator(UPDATE_MANUFACTURE_NOW, 'manufactureNow');
export const updateIsLayby = makeActionCreator(UPDATE_IS_LAYBY, 'isLayby');
export const updateLaybyQuote = makeActionCreator(UPDATE_LAYBY_QUOTE, 'laybyQuote');
export const updateIsGift = makeActionCreator(UPDATE_IS_GIFT, 'isGift');
export const updateLaybyPrefferedPaymentDay = makeActionCreator(UPDATE_LAYBY_PREFERRED_PAYMENT_DAY, 'laybyPreferredPaymentDay');
export const updateIsMultiPlate = makeActionCreator(UPDATE_IS_MULTIPLATE, 'isMultiPlate');
export const updatePersonalDetails = makeActionCreator(UPDATE_PERSONAL_DETAILS, 'checkout')
export const updateOwnerAddress = makeActionCreator(UPDATE_OWNER_ADDRESS, 'ownerAddress')
export const updateDeliveryAddress = makeActionCreator(UPDATE_DELIVERY_ADDRESS, 'deliveryAddress')
export const updatePaymentType = makeActionCreator(UPDATE_PAYMENTTYPE,'paymentType');
export const updateIsAutopay = makeActionCreator(UPDATE_IS_AUTOPAY, 'isAutopay');
export const updateIsDealerOrder = makeActionCreator(UPDATE_IS_DEALERORDER, 'isDealerOrder');
export const updateDealerAddress = makeActionCreator(UPDATE_DEALER_ADDRESS, 'dealerAddress');
export const updateIsAcceptStatement = makeActionCreator(UPDATE_IS_ACCEPT_STATEMENT, 'isAcceptStatement');
export const updateGiftingType = makeActionCreator(UPDATE_GIFTING_TYPE, 'giftingType');
export const updateIsGifting = makeActionCreator(UPDATE_IS_GIFTING, 'isGifting');

export const clearBasket = makeActionCreator(CLEAR_BASKET);
export const clearBasketAndCheckout = makeActionCreator(CLEAR_BASKET_AND_CHECKOUT);
export const clearDeliveryAddress = makeActionCreator(CLEAR_DELIVERY_ADDRESS);

export const removePromotion = makeActionCreator(REMOVE_PROMOTION,"apiUrl","basketId");
