export const GiftingType = {
    None:{
        id: 0,
        name: "Not Gifting",
        code: "None",
        price: 0
    },
    ECard:{
        id: 1,
        name: "E-Card",
        code: "ECard",
        price: 0.00
    },
    GiftCard: {
        id: 2,
        name: "Gift Card",
        code: "GiftCard",
        price: 20.00
    },
    Supplementary: {
        id: 3,
        name: "Supplementary plate",
        code: "Supplementary",
        price: 60.00
    }
}