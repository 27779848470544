import {Record} from 'immutable'


// export const currentBasketSelector = createSelector(
//     state => state.get(`currentBasket`),
//     currentBasket => currentBasket
// )

export const currentBasketSelector = (state:Record<any>) => {
    return state.get('currentBasket');
}