import { createStore, applyMiddleware, compose } from "redux"
import { reducer } from "./combineReducers"
import { initSagas } from "./initSaga"
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

const composedEnhancers =
  typeof window !== "undefined"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose
// const composedEnhancers = compose(
//     applyMiddleware(...middleware)
//   )

export default preloadedState => {
  const configStore = createStore(
    reducer,
    preloadedState,
    composedEnhancers(applyMiddleware(...middleware))
  )

  initSagas(sagaMiddleware)

  return configStore
}
